import env from "@/env.mjs";
import { typeByExtension } from "@std/media-types";

export const getMimeType = (url: string) => {
  const mimeType = typeByExtension(url.split(".").pop() || "");
  return mimeType;
};

const getStreamVideoUrl = (url: string | undefined) => {
  if (!url) {
    return undefined;
  }
  const mime = getMimeType(url);

  if (!mime?.startsWith("video/")) {
    return url;
  }

  return `${
    env.NEXT_PUBLIC_STREAM_URL
  }/video/v1?url=${encodeURIComponent(url)}`;
};

export { getStreamVideoUrl };
