"use client";
import getLink from "@/lib/storyblok/utils/getLink";

import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import { MegaMenuSectionStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import { default as LinkNext } from "next/link";
import { usePathname } from "next/navigation";
import { Dispatch, SetStateAction } from "react";
import MobileMenuModal from "./Mobile/MobileMenuModal/MobileMenuModal";
import styles from "./index.module.css";

interface MenuSectionLinkType {
  link: MegaMenuSectionStoryblok;
  open?: boolean;
  setActiveSection?: Dispatch<SetStateAction<string>>;
}

const addHex = (hex: string) => {
  return hex.startsWith("#") ? hex : `#${hex}`;
};

export const MenuSectionLink = ({
  link,
  open,
  setActiveSection,
}: MenuSectionLinkType) => {
  const href = getLink(link.link);
  const handleSetActiveSection = () => setActiveSection?.(link._uid);
  const pathname = usePathname();
  return (
    <li>
      <span
        style={
          {
            "--menu-link-color": link.textColor
              ? addHex(link.textColor)
              : undefined,
          } as React.CSSProperties
        }
        className={clsx(
          styles.menuSectionLink,
          open && styles.open,
          styles.desktopModal,
          (pathname === href || pathname + "/" === href) && styles.current,
        )}
        onMouseEnter={handleSetActiveSection}
        onMouseLeave={() => setActiveSection && setActiveSection("")}
      >
        <LinkNext className={styles.menuLink} href={href}>
          {link.text}
        </LinkNext>
      </span>
      <span className={styles.mobileModal}>
        <MobileMenuModal link={link} />
      </span>
    </li>
  );
};

const FirstSection = ({ link }: IMenuSectionType) => {
  const { links } = link;

  return (
    <div className={styles.desktopMenu__column}>
      <ul className={styles.desktopMenu__sectionLinks}>
        {links?.map((link: any) => (
          <MenuSectionLink link={link} key={link._uid} />
        ))}
      </ul>
    </div>
  );
};

export interface IMenuSectionType {
  link: MegaMenuSectionStoryblok;
  active?: boolean;
  setActiveSection?: Dispatch<SetStateAction<string>>;
}

export const MenuSectionItem = ({
  link,
  active,
  setActiveSection,
}: IMenuSectionType) => {
  const { links, card } = link;
  const handleSetActiveSection = () => setActiveSection?.(link._uid);
  if (!links?.length) {
    return null;
  }

  return (
    <div
      className={styles.desktopMenu__wrap}
      data-layout="page"
      onMouseEnter={handleSetActiveSection}
    >
      <FirstSection link={link} />

      {card && card.length > 0 && (
        <div className={styles.desktopMenu__bloks}>
          {card.map((item: any) => (
            <StoryblokComponent blok={item} key={item._uid} />
          ))}
        </div>
      )}
    </div>
  );
};

export const MenuSectionModal = ({
  children,
  open,
  setActiveSection,
}: {
  children: React.ReactNode;
  open: boolean;
  setActiveSection: Dispatch<SetStateAction<string>>;
}) => {
  const menuSectionModalStyles = `${open ? styles.active : ""} ${
    open ? "active" : ""
  } ${styles.desktopMenu__section} menu-section`;

  const a11yProps = open ? {} : ({ "aria-hidden": true, inert: "" } as any);

  return (
    <div
      {...a11yProps}
      onMouseLeave={() => setActiveSection?.("")}
      className={menuSectionModalStyles}
    >
      {children}
    </div>
  );
};
